@mixin article-container {
  #article-container {
    h1 {
      width: 100%;
      text-align: center;

      font-family: 'solano-gothic-bd', serif;
      font-size: 3.8em;

      text-transform: uppercase;
    }

    h4 {
      font-size: 3em;
      font-family: 'solano-gothic-bd', serif;

      text-align: center;

      text-transform: uppercase;

      margin: 1.25em;
    }

    p {
      margin: 1em 0;
      font-size: 1.8em;
      font-family: 'georgia', serif;
      line-height: 1.85em;
      padding-left: 7%;
      padding-right: 7%;

      &.byline {
        margin: 1em auto;

        font-size: 2.35em;
        text-align: center;

        font-family: 'solano-gothic-lt', serif;
      }
    }

    photocap {
      font-size: .9em;
      color: black;
      font-style: italic;
      justify-content: center;
      text-align: center;
      padding-left: 25%;
      padding-right: 25%;
    }
      
    a {
      text-decoration: underline;

      transition: all 250ms ease-in-out;

      &:hover {
        background-color: $selectionColor;
        color: $selectionTextColor;
      }
    }

    video, img {
      width: 50%;
      padding-left: 25%;
      padding-right: 25%;
      object-fit: contain;
      align-items: center;
      justify-self: center;
      justify-content: center;
      align-self: center;
      align-content: center;
      justify-items: center;

      + .caption {
        font-size: 1.3em;
        color: gray;
        font-style: italic;
      }

      &.vertical {
        max-width: 440px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }
    }

    .main-content {
      display: flex;
      flex-direction: row;

      .timeline {
        display: none;
      }

      .timeline-event {
        font-size: 0.75em;
        padding: 3em 0;
        padding-right: 1.5em;
        text-align: center;

        p {
          margin: 0;
        }
      }

      .timeline-bar {
        width: 2px;
        background-color: black;
      }
    }
  }
}

@media screen and (min-width: $laptop-width) {
  #page-container #article-container {
    h1 {
      font-size: 5em;
    }

    .main-content .timeline {
      display: flex !important;
      flex-direction: column;
      align-items: center;
    }
  }

  .wrap {
    max-width: 500px;
    margin: 0.5em 1em !important;

    &.left {
      float: left;
    }

    &.right {
      float: right;
    }
  }
}
