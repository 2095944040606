@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@font-face {
    font-family: solano-gothic-bd;
    src: url("../fonts/SolanoGothicMVB-Bd.otf");
}

@font-face {
    font-family: solano-gothic-lt;
    src: url("../fonts/SolanoGothicMVB-Md.otf");
}

@font-face {
    font-family: georgia;
    src: url("../fonts/georgia.ttf");
}