.nav-full {
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 1em;

  height: $navHeight;

  transition: opacity 250ms ease-in-out;

  color: white;
  font-size: 2em;
  font-family: 'solano-gothic-bd';
  font-weight: bold;
  text-transform: uppercase;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  * {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .title {
    background-image: url('/assets/watercolor-short-bg.png');

    width: 200px;
  }

  .pages {
    // On mobile and tablet screens, sticky page navigation doesn't show
    display: none;
  }

  a {
    transition: all 250ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0);

    &:hover {
      border-color: white;
    }
  }
}

@media screen and (min-width: $laptop-width) {
  .nav-full {
    justify-content: space-between;
  }

  .nav-full .pages {
    display: flex;
    background-image: url('/assets/watercolor-long-bg.png');

    height: 550px;

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      a {
        margin: 1em;
      }
    }
  }
}
