@import './utils.scss';
@import './fonts.scss';
@import './loading.scss';

@import './home.scss';
@import './article';

html, body, #root {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  font-size: 11px;
}

a {
  color: inherit;
  text-decoration: none;
}

*::selection {
  background-color: $selectionColor;
  color: $selectionTextColor;
}
